import axios from '@/axios/globalaxios'

//币种余额转换
export const findUserTotalAmount  = (data) => {
  return axios({
    url : '/tradelab/forexOrder/findUserTotalAmount',
    method: 'post',
    data:data
  })
}

// 查找人民币走势图
export const findCnyAssetTrendByAcid =(data) =>{

  return axios({
    url:'/tradelab/forexOrder/findCnyAssetTrendByAcid',
    method: 'post',
    data:data
  })
}